import { AnyTheme } from 'base/components/GenericSlot';
import gridSystemConfig from 'base/configs/grid.system';
import { keys, transform } from 'lodash-es';
import { prose, typography } from 'modules/tailwind/theme.config';
import { extendTailwindMerge, fromTheme, twJoin } from 'tailwind-merge';
import gridSystemPlugin from 'tailwind-utils/gridSystem.plugin';
import { createTV } from 'tailwind-variants';
import tailwindConfig from 'tailwind.config';
import { isNotNull, isObject, isString } from 'typesafe-utils';

type ExtraClassGroups = 'prose' | 'typography' | 'wings';
type ExtraThemeGroups = never;

// Extracted from tailwind-merge types
type ClassNameValue = ClassNameArray | string | null | undefined | 0 | 0n | false;
type ClassNameArray = ClassNameValue[];

const extendedTheme = { ...tailwindConfig?.theme?.extend };

const fromExtendedTheme = (key: string) => (classPart: string) => {
  return keys(extendedTheme[key]).includes(classPart);
};

const isCommonProse = (classPart: string) => {
  return /(coverbox|factbox)/.test(classPart);
};

const isCommonTypograph = (classPart: string) => {
  return /(headline|body|preamble|blockquote|input|quote|content)-?\d?(xs|sm|md|lg|xl)?/.test(classPart);
};

const withGridSystem = transform(
  { ...gridSystemPlugin(gridSystemConfig).config?.theme?.extend },
  (result, value, key) => {
    const dictionary = { maxWidth: 'max-w', minWidth: 'min-w', width: 'w' };
    const resolvedKey = dictionary[key as keyof typeof dictionary];

    if (resolvedKey) {
      result[resolvedKey] = [{ [resolvedKey]: [...Object.keys(value), 'grid'] }];
    }

    const extraKeys = [
      'm',
      'ml',
      'mr',
      'mt',
      'mb',
      'mx',
      'my',
      'p',
      'pl',
      'pr',
      'pt',
      'pb',
      'px',
      'py',
      'gap',
      'gap-x',
      'gap-y',
    ];

    extraKeys.forEach((key) => {
      result[key] = [{ [key]: ['grid', 'grid-1/2', 'grid-m', 'grid-m-1/2', 'grid-gap', 'grid-gap-1/2'] }];
    });

    return result;
  },
  {} as Record<string, any>,
);

const twMergeConfig = {
  extend: {
    classGroups: {
      'font-size': [{ text: [isCommonTypograph, ...typography], prose: [isCommonProse, ...prose] }],
      wings: [{ wings: ['', 'none'] }],
      'wings-color': [{ wings: [fromTheme('color'), 'none'] }],
      shadow: [{ shadow: [fromExtendedTheme('boxShadow')] }],
      aspect: [{ aspect: [fromExtendedTheme('aspectRatio')] }],
      z: [{ z: [fromExtendedTheme('z')] }],
      ...withGridSystem,
    },
  },
};

const customTV = createTV({ twMergeConfig });

const classNameArrayToString = (array: ClassNameArray): string => {
  return array
    .reduce<string[]>((result, value) => {
      if (Array.isArray(value)) {
        result.push(...classNameArrayToString(value));
      }

      if (isString(value)) {
        result.push(value);
      }

      return result;
    }, [] as string[])
    .join(' ');
};

const withClassNameStrings = (theme: AnyTheme): void => {
  if (theme.base) {
    theme.base = deepConvertClassName(theme.base);
  }

  if (theme.slots) {
    theme.slots = deepConvertClassName(theme.slots);
  }

  if (theme.variants) {
    theme.variants = deepConvertClassName(theme.variants);
  }
};

const deepConvertClassName = (value: any) => {
  if (Array.isArray(value)) {
    return classNameArrayToString(value);
  }

  if (isObject(value) && isNotNull(value)) {
    for (const key in value) {
      value[key] = deepConvertClassName(value[key]);
    }
  }

  return value;
};

export const twTheme: typeof customTV = (theme) => {
  withClassNameStrings(theme);

  return customTV(theme);
};

export const twMerge = extendTailwindMerge<ExtraClassGroups, ExtraThemeGroups>(twMergeConfig);

export const tw = {
  theme: twTheme,
  merge: twMerge,
  join: twJoin,
};
