import { useStableReducer } from '@/hooks/useStable';
import { AccessLevel, Device, MetaPageType, PageType } from '@/types/app';
import { Component } from '@/types/component';
import { RecommendedArticle, ScrollArticle } from 'lib/data/recommendations';
import { Dispatch, createContext } from 'react';

export interface AppState extends Record<string | number, any> {
  accessLevel?: AccessLevel;
  affiliate?: string;
  alltIdUserCode?: string;
  category?: string;
  device: Device;
  gamesUrls?: string[];
  isArticlePage: boolean;
  isDesktop: boolean;
  isFrontPage: boolean;
  isMobile: boolean;
  isNativeAd?: boolean;
  isPreviewPage: boolean;
  metaPageType?: MetaPageType;
  pageId?: number;
  publishedUrl?: string;
  purchaseTag?: string;
  recommendedArticles: RecommendedArticle[];
  scrollArticles: ScrollArticle[];
  type: PageType;
}

export type AppStateValue = [AppState, Dispatch<Partial<AppState>>];

export const AppStateContext = createContext<AppStateValue>([{} as AppState, () => {}]);

export const AppStateProvider: Component<AppState> = ({ children, ...initialPageState }) => {
  const value = useStableReducer((state: AppState, update: Partial<AppState>) => {
    return { ...state, ...update };
  }, initialPageState as AppState);

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};
