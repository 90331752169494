import { ReactNode } from 'react';
import { isElement, isValidElementType } from 'react-is';
import { TypeGuard } from 'typesafe-utils';

export const isReactNode = <T>(value: T): value is TypeGuard<ReactNode, T> =>
  isValidElementType(value) || isElement(value);

export const isIterable = <T>(value: T): value is TypeGuard<Iterable<unknown>, T> =>
  typeof value === 'object' && typeof (value as any)?.[Symbol.iterator] === 'function';

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const isFunction = <T>(value: T): value is TypeGuard<Function, T> => typeof value === 'function';
