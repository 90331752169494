import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Main } from './Main';

export interface StandaloneMainProps extends StandaloneComponentProps<typeof Main> {
  content?: ReactNode;
}

export const StandaloneMain: StandaloneComponent<StandaloneMainProps> = ({ content, options, ...props }) => {
  return (
    <Main {...options} {...props}>
      {content}
    </Main>
  );
};
