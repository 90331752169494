import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getDomain } from '@/utils/getDomain';
import { getUrl } from '@/utils/getUrl';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { isNotNull, isNotUndefined } from 'typesafe-utils';
import { Link } from './Link';

export interface StandaloneLinkProps extends StandaloneComponentProps<typeof Link> {
  content?: React.ReactNode;
  href?: ComponentProps<typeof Link>['href'];
  isOptional?: boolean;
  target?: ComponentProps<typeof Link>['target'];
}

export const StandaloneLink: StandaloneComponent<StandaloneLinkProps> = ({
  content,
  href,
  isOptional,
  options,
  ...props
}) => {
  const domain = getDomain(false);
  const url = getUrl(href) || getUrl(href, domain);
  const isHrefValid = isNotUndefined(href) && isNotNull(url);
  const isHrefExternal = isHrefValid && url.origin !== domain;
  const resolvedHref = isHrefValid
    ? isHrefExternal
      ? url?.href?.replace(/(\/)(?=\?|$)/, '')
      : url?.pathname + url?.search
    : undefined;

  if (isOptional && !resolvedHref) {
    return <>{content}</>;
  }

  return (
    <Link href={resolvedHref} {...withSafeInnerHTML(content)} {...options} {...props}>
      {withNonHTMLChildren(content)}
    </Link>
  );
};
