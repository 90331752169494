import { Component, ComponentProps } from '@/types/component';
import NextLink from 'next/link';
import { isNotUndefined } from 'typesafe-utils';

interface BaseProps extends ComponentProps<'a'> {
  type?: 'reload' | 'next-link' | 'force-reload' | 'force-next-link';
}

export const BaseSlot: Component<BaseProps> = ({ children, ...props }) => {
  const { type, href } = props;

  const resolvedType = type || process.env.OCELOT_LINK_TYPE || 'reload';
  const isNextLink = ['next-link', 'force-next-link'].includes(resolvedType);

  if (isNextLink && isNotUndefined(href))
    return (
      <NextLink href={href} {...props} suppressHydrationWarning>
        {children}
      </NextLink>
    );

  return (
    <a {...props} suppressHydrationWarning>
      {children}
    </a>
  );
};
