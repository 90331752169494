import { IconTheme } from '@/components/Icon/theme';
import { logger } from '@/utils/logger';
import { GenericSlot } from 'base/components/GenericSlot';
import { ComponentProps } from 'react';
import { iconsDictionary } from './Icon.dictionary';

interface BaseProps extends ComponentProps<'svg'> {
  dictionary?: typeof iconsDictionary;
}

export const Icon = GenericSlot({
  theme: IconTheme,
  as: (props: BaseProps) => null,
  render: ({ props }) => {
    const name = props.name as keyof typeof iconsDictionary;
    const dictionary = { ...iconsDictionary, ...props.dictionary };
    const Icon = dictionary[name];

    if (!Icon) {
      logger.error(`Icon "${name}" not found`);
      return <></>;
    }

    return <Icon {...props} />;
  },
});
