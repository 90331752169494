/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "accordion": {
    "extend": {
      "toggle_variant_default": "sm:hidden",
      "content_variant_default": "sm:block"
    }
  },
  "bar_group_colors_default": "bg-white sm:bg-yellow-600 wings-yellow-600",
  "bar_group_size_default": "-mx-4 mt-3 lg:mt-0",
  "bar_group_variant_default": "relative overflow-x-auto overflow-y-hidden row-start-2 col-span-3 -mx-4 lg:row-auto lg:col-auto lg:mx-0 lg:group-data-[open]:max-w-screen-sm",
  "bar_colors_default": "bg-yellow-600 wings-yellow-600",
  "bar_size_default": "p-grid-m pt-3 lg:py-3 gap-x-1 sm:gap-x-6 lg:gap-x-4.5",
  "bar_variant_default": "grid grid-cols-[auto_1fr_auto] lg:grid-cols-[auto_auto_1fr_auto] items-center relative wings !z-2 overflow-x-hidden overflow-x-auto lg:overflow-x-visible",
  "drawer_toggle_animation_default": "",
  "drawer_toggle_animation_alternative": "",
  "drawer_toggle_colors_default": "",
  "drawer_toggle_size_default": "",
  "drawer_toggle_variant_default": "",
  "drawer_colors_default": "bg-white sm:wings-white",
  "drawer_size_default": "px-3 py-5 w-full h-[calc(100dvh_-_100%)] gap-y-5 sm:px-8 md:px-16 lg:px-25 sm:py-4 md:py-5 lg:py-6",
  "drawer_variant_default": "-translate-y-[120%] -z-2 absolute flex-col flex group-data-[open]:translate-y-0 left-0 overflow-y-auto top-full transition-transform wings lg:overflow-visible overflow-x-hidden",
  "logo_colors_default": "text-primary-900",
  "logo_colors_alternative": "",
  "logo_size_default": "h-7 sm:h-14 flex-shrink-0 [&_svg]:h-full mx-1 sm:mx-0",
  "logo_size_alternative": "",
  "logo_variant_default": "transition-all",
  "menu_group_colors_default": "",
  "menu_group_size_default": "md:gap-x-6 mt-4 sm:mt-7",
  "menu_group_variant_default": "columns-1 sm:columns-2 md:grid md:grid-cols-3 lg:grid-cols-4",
  "menu_colors_primary": "",
  "menu_colors_secondary": "",
  "menu_colors_none": "",
  "menu_size_bar": "flex gap-x-1 px-0 h-12 lg:gap-x-0",
  "menu_size_controls": "gap-x-1 sm:gap-x-2",
  "menu_size_drawer": "gap-0 md:first-of-type:row-span-2 md:second-of-type:row-span-3",
  "menu_variant_bar": "flex group-data-[open]:hidden flex-row items-center overflow-x-auto overflow-y-hidden",
  "menu_variant_controls": "flex flex-row items-center justify-self-end group-data-[open]/menu:hidden sm:group-data-[open]/menu:flex",
  "menu_variant_drawer": "flex flex-col [&_svg]:text-gray-900",
  "search_toggle_colors_default": "bg-primary-800 sm:bg-transparent text-white sm:text-primary-700",
  "search_toggle_size_default": "",
  "search_toggle_variant_default": "cursor-pointer relative",
  "search_colors_default": "bg-transparent",
  "search_size_bar": "w-full h-12 gap-x-2",
  "search_size_drawer": "w-full h-12 gap-x-2 max-w-screen-sm mx-auto",
  "search_variant_bar": "hidden flex-row items-center lg:group-data-[open]:flex",
  "search_variant_drawer": "flex flex-row items-center [&_button]:hidden sm:[&_button]:block lg:hidden",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3 mt-5 md:row-span-2 lg:row-span-1",
  "soMe_group_variant_default": "flex flex-wrap self-start md:col-start-1 md:row-start-3 lg:col-start-4",
  "soMe_headline_colors_default": "",
  "soMe_headline_size_default": "w-full text-body-md",
  "soMe_headline_variant_default": "text-gray-900 uppercase sm:normal-case",
  "soMe_colors_default": "",
  "soMe_size_default": "gap-3",
  "soMe_variant_default": "self-end flex flex-wrap",
  "textField_input_colors_default": "",
  "textField_input_size_default": "!w-full",
  "textField_input_size_large": "!w-full",
  "textField_input_variant_default": "",
  "textField_colors_default": "",
  "textField_size_default": "",
  "textField_variant_default": "flex-grow",
  "colors_default": "",
  "size_default": "w-full max-w-grid mx-auto",
  "variant_default": "sticky top-0 left-0 z-menu group group/menu",
  "soMe_size_alternative": "gap-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Navigation");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;