const plugin = require('tailwindcss/plugin');
const flattenColorPalette = require('tailwindcss/lib/util/flattenColorPalette').default;
const withAlphaValue = require('tailwindcss/lib/util/withAlphaVariable').withAlphaValue;

const wingsPlugin = plugin(({ addUtilities, matchUtilities, theme }) => {
  addUtilities({
    '.wings': {
      zIndex: '1',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: '-1',
        width: '50vw',
        height: '100%',
        top: '0',
        right: '50%',
        backgroundColor: 'var(--tw-wings-color, transparent)',
        backgroundImage: 'var(--tw-wings-image, none)',
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: '-1',
        width: '50vw',
        height: '100%',
        top: '0',
        left: '50%',
        backgroundColor: 'var(--tw-wings-color, transparent)',
        backgroundImage: 'var(--tw-wings-image, none)',
      },
    },
    '.wings-none': {
      '&:before': { display: 'none' },
      '&:after': { display: 'none' },
    },
  });

  matchUtilities(
    {
      wings: (value, { modifier }) => ({
        '--tw-wings-opacity': `${(modifier ?? 100) / 100}`,
        '--tw-wings-color': withAlphaValue(value, 'var(--tw-wings-opacity)'),
        '--tw-wings-image': value,
      }),
    },
    { values: flattenColorPalette(theme('colors')), modifiers: 'any' },
  );
});

module.exports = wingsPlugin;
