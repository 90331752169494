import {
  ElementKey,
  InstanceofProp,
  ProxyProp,
  StandaloneComponent,
  StandaloneComponentProps,
} from '@/types/component';
import { isFunction } from '@/types/is';
import { PartPartial } from '@/types/utils';
import { isIntrinsicElement } from '@/utils/isIntrinsicElement';
import { logger } from '@/utils/logger';
import { arrayCustomizer, Merge, MergeCustomizer, withCustomizers } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { InstanceofSlotComponent, InstanceofStandaloneComponent, withSlotInstanceof } from '@/utils/withInstanceofProp';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { mergeWith, omitBy, pickBy, upperFirst } from 'lodash-es';
import React, { ComponentProps, ReactElement } from 'react';
import { TVReturnType, VariantProps } from 'tailwind-variants';
import { isNotUndefined, isNull, isUndefined } from 'typesafe-utils';

// @ts-expect-error: shortcut for inferred generics
export type AnyTheme = TVReturnType;

type NullableObjectProps<T> = {
  [K in keyof T]: T[K] extends object ? NullableObjectProps<T[K]> : T[K] | null | undefined;
};

type NullableVariantProps<Theme extends AnyTheme> = NullableObjectProps<VariantProps<Theme>>;

type AnyContext = React.Context<any>;

type ContextProp<Context extends AnyContext> = { context?: Context };

type ChildrenProp = { children?: React.ReactNode };

type ThemeProp<Theme extends AnyTheme> = { theme?: Theme };

type RefProp<Element extends ElementKey> = { ref?: React.RefObject<React.ComponentRef<Element>> };

type DebugProp = { debug?: boolean };

type Slots<Theme extends AnyTheme> =
  | keyof Theme['slots']
  | keyof Theme['extend']['slots']
  | keyof Theme['embed']
  | keyof Theme['extend']['embed'];

type InferredOptions<Element extends ElementKey, Theme extends AnyTheme> =
  Element extends InstanceofProp<typeof InstanceofStandaloneComponent>
    ? Element extends StandaloneComponent<infer Props>
      ? Props extends StandaloneComponentProps<infer Component, infer Extras>
        ? Omit<Props, 'options'> & StandaloneComponentProps<Component, Extras, NullableVariantProps<Theme>>
        : never
      : never
    : React.ComponentProps<Element> & NullableVariantProps<Theme>;

type GenericFunctionComponentProps<
  Element extends ElementKey,
  Theme extends AnyTheme,
  Context extends AnyContext,
> = InferredOptions<Element, Theme> &
  ProxyProp<Element> &
  RefProp<Element> &
  ThemeProp<Theme> &
  ContextProp<Context> &
  ChildrenProp &
  DebugProp;

export type GenericSlotFunction<Element extends ElementKey, Theme extends AnyTheme, Context extends AnyContext> = ((
  props: GenericFunctionComponentProps<Element, Theme, Context>,
) => React.ReactNode) & {
  displayName?: React.FunctionComponent['displayName'];
} & ProxyProp<Element>;

export type GenericSlotRender<Element extends ElementKey> = (props: {
  element: ReactElement<ComponentProps<Element>, Element>;
  props: ComponentProps<Element>;
  children: React.ReactNode;
  ref?: React.ForwardedRef<React.ComponentRef<Element>>;
}) => JSX.Element;

export type GenericSlotProps<Element extends ElementKey, Theme extends AnyTheme, Context extends AnyContext> = {
  theme: Theme;
  slot?: Slots<Theme>;
  render?: GenericSlotRender<Element>;
} & Required<ProxyProp<Element>> &
  ContextProp<Context> &
  DebugProp;

export type GenericSlot = <
  Element extends ElementKey,
  Theme extends AnyTheme,
  Context extends AnyContext,
  InferredElement extends ElementKey = Element extends ProxyProp<infer Proxy> ? Proxy : Element,
>(
  props: GenericSlotProps<Element, Theme, Context>,
) => GenericSlotFunction<InferredElement, Theme, Context>;

// @ts-expect-error: type mismatch for `as` when using extended slot
export const GenericSlot: GenericSlot = ({ render, slot, ...generic }) => {
  const defaultContext = React.createContext({});

  const Slot: ReturnType<GenericSlot> = React.forwardRef(
    ({ as, children, theme, debug, options: unsafeOptions, ...unsafeProps }, ref) => {
      const props = omitBy(unsafeProps, isUndefined);
      const options = omitBy(unsafeOptions, isUndefined);

      const Element = (as || generic.as || 'div') as ElementKey;
      const isStringElement = typeof Element === 'string';
      const isCustomElement = isStringElement && !isIntrinsicElement(Element);
      const resolvedClass = isCustomElement ? 'class' : 'className';

      // @ts-expect-error: `$$instanceof` is a custom property
      const isExtendedSlot = Element.$$instanceof === InstanceofSlotComponent;

      // @ts-expect-error: `$$instanceof` is a custom property
      const isStandaloneComponent = Element.$$instanceof === InstanceofStandaloneComponent;

      const ImplicitContext = (generic.context || defaultContext) as React.Context<any>;
      const implicitContextValue = React.useContext(ImplicitContext);

      const ExplicitContext = (props.context || defaultContext) as React.Context<any>;
      const explicitContextValue = React.useContext(ExplicitContext);

      const { Provider } = ImplicitContext;

      const resolvedTheme = [implicitContextValue.theme, theme, generic.theme].find(isFunction);
      const embeddedTheme = resolvedTheme?.embed?.[slot];

      if (!resolvedTheme) {
        throw new Error(
          `Theme is missing. Check if you've provided a theme to the GenericSlot and that the provided theme is exported.`,
        );
      }

      const { variants = {}, variantKeys = [] } = embeddedTheme || resolvedTheme || {};

      const isVariant = (value: any, key: string): boolean => {
        const validKeys = Object.keys({ ...variants[key] });

        const isValidKey = variantKeys.includes(key);
        const isValidValue = validKeys.includes(String(value)) || isNull(value);

        const isBooleanValue = value === true || value === false;
        const isValidBooleanValue = isBooleanValue && validKeys.some((v) => ['true', 'false'].includes(v));

        return isValidKey && (isValidValue || isValidBooleanValue);
      };

      const isContext = (value: any, key: string): boolean => {
        const isSlot = key.startsWith('$');
        const isInternal = ['context', 'theme'].includes(key);

        return isNotUndefined(value) && (isSlot || isInternal || isVariant(value, key));
      };

      const slotKey = `$${String(slot)}`;

      const relatedOptions = {
        ...implicitContextValue,
        ...implicitContextValue?.[slotKey],
        ...explicitContextValue,
        ...explicitContextValue?.[slotKey],
        ...props,
        ...options,
        ...options?.[slotKey],
      };

      const resolvedRef = ref || props.ref || relatedOptions.ref;
      const resolvedVariants = pickBy(relatedOptions, isVariant);

      const resolvedStyles = !(isExtendedSlot || isStandaloneComponent)
        ? ((resolvedTheme()?.[slot || 'base'] || resolvedTheme)?.(resolvedVariants) as string)
        : undefined;

      const resolvedContext = pickBy(
        {
          theme,
          ...explicitContextValue,
          ...props,
          ...options,
        },
        isContext,
      );

      const withContextProvider = Object.keys(resolvedContext).length > 0 && !(isExtendedSlot || isStandaloneComponent);
      const withEmbeddedTheme = isStandaloneComponent && embeddedTheme;

      const resolvedProps = omitBy(
        { ...implicitContextValue?.[slotKey], ...props, ...options, ...options?.[slotKey] },
        isContext,
      );

      const resolvedElementProps: ComponentProps<typeof Element> = {};

      switch (true) {
        case isExtendedSlot:
          Object.assign(resolvedElementProps, {
            theme: resolvedTheme,
            context: generic.context,
            ...resolvedVariants,
            ...resolvedProps,
          });
          break;
        case isStandaloneComponent:
          Object.assign(resolvedElementProps, {
            theme: embeddedTheme || resolvedTheme,
            context: generic.context,
            options: {
              ...resolvedVariants,
              ...options,
            },
            ...props,
          });
          break;
        default:
          Object.assign(resolvedElementProps, merge({ [resolvedClass]: resolvedStyles }, resolvedProps));
          break;
      }

      let element = (
        <Element {...withSafeInnerHTML(children)} {...resolvedElementProps} ref={resolvedRef}>
          {withNonHTMLChildren(children)}
        </Element>
      );

      if (render) {
        element = render({ element, props: resolvedElementProps, children, ref: resolvedRef });
      }

      if (withContextProvider) {
        element = <Provider value={resolvedContext}>{element}</Provider>;
      }

      if (withEmbeddedTheme) {
        element = <Provider value={{ ...resolvedContext, theme: embeddedTheme }}>{element}</Provider>;
      }

      if (generic.debug || debug) {
        logger.debug({
          Element,
          GenericSlot: { render, slot, generic },
          Slot: { as, children, theme, options, props, ref },
          context: {
            ImplicitContext,
            implicitContextValue,
            ExplicitContext,
            explicitContextValue,
          },
          resolved: {
            resolvedClass,
            resolvedContext,
            resolvedElementProps,
            resolvedProps,
            resolvedRef,
            resolvedStyles,
            resolvedTheme,
            resolvedVariants,
          },
          is: {
            isStringElement,
            isCustomElement,
            isExtendedSlot,
            isStandaloneComponent,
          },
          others: {
            relatedOptions,
            embeddedTheme,
          },
        });
      }

      return element;
    },
  );

  if (!Slot.displayName) {
    Slot.displayName = upperFirst(slot?.toString()) || 'Base';
  }

  withSlotInstanceof(Slot);

  return Slot;
};

export type GenericSlotFactoryProps<Theme extends AnyTheme, FactoryContext extends AnyContext> = {
  theme: Theme;
  context?: FactoryContext;
  debug?: boolean;
};

type Defined<A, B> = A extends undefined ? B : A;

export const GenericSlotFactory = <FactoryTheme extends AnyTheme, FactoryContext extends AnyContext>(
  factoryProps: GenericSlotFactoryProps<FactoryTheme, FactoryContext>,
) => {
  const context = factoryProps?.context ?? React.createContext({});

  const slot = <Element extends ElementKey, Theme extends AnyTheme = undefined>(
    props: PartPartial<GenericSlotProps<Element, Defined<Theme, FactoryTheme>, typeof context>, 'theme'>,
  ) =>
    GenericSlot<Element, Defined<Theme, FactoryTheme>, typeof context>({
      ...factoryProps,
      context,
      ...props,
    });

  return slot;
};

const classMergeCustomizer: MergeCustomizer = (a, b, key) => {
  if (key === 'className' || key === 'class') {
    return tw.merge(a, b);
  }
};

const merge: Merge = (...props) => mergeWith({}, ...props, withCustomizers(classMergeCustomizer, arrayCustomizer));
